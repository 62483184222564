import React from "react";
import Image from "@ui/image";
import { ImageWrapper, ImageWrapperInner } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "@ui/section-title";

const ImageSection = ({ data }) => {
  return (
    <ImageWrapper>
      <ImageWrapperInner>
        <Container>
          <Row>
            <Col lg={12}>
              <Image
                src={data.images[0]?.src}
                alt={data.images[0]?.alt || "Icon"}
              />
            </Col>
            <Col lg={12}>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
        </Container>
      </ImageWrapperInner>
    </ImageWrapper>
  );
};

export default ImageSection;
