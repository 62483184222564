import styled, { css, themeGet, device } from "@styled";

export const IntroWrapper = styled.section`
  padding: 60px 0 100px;
  ${device.medium} {
    padding: 100px 0 150px;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: #fff;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: #f8f8f8;
    `}
`;

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 20px;
  }
`;

export const Styledtitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${themeGet("colors.primary")};
`;
export const RightBox = styled.div`
  margin: 15px 0;
  ${device.large} {
    margin: 0;
  }
`;
