import styled, { device } from "@styled";

export const ImageWrapper = styled.section`
  padding: 80px 0 40px;
  ${device.large} {
    padding: 80px 0 40px;
  }
`;
export const ImageWrapperInner = styled.div`
  p {
    text-align: center;
  }
`;
