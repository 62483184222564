import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Button from "@ui/button";
import Text from "@ui/text";
import { IntroWrapper, LeftBox, RightBox } from "./stye";

const IntroArea = ({ data, layout, ...props }) => {
  return (
    <IntroWrapper layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col>
            <SectionTitle
              subtitle={data.section_title?.subtitle}
              title={data.section_title?.title}
              description={data.section_title?.description}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            {!!data.images && (
              <RightBox>
                <Image
                  src={data.images[0]?.src}
                  alt={data.images[0]?.alt || "Info"}
                />
              </RightBox>
            )}
          </Col>
          <Col lg={7} className="d-flex align-items-center">
            <LeftBox>
              {data?.items?.map((el, index) => (
                <Text
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: el.description,
                  }}
                ></Text>
              ))}
              {data?.buttons?.map(({ id, index, content, ...rest }) => (
                <Button key={id} m="7px" {...rest}>
                  {content}
                </Button>
              ))}
            </LeftBox>
          </Col>
        </Row>
      </Container>
    </IntroWrapper>
  );
};

IntroArea.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3]),
};
IntroArea.defaultProps = {
  layout: 1,
};
export default IntroArea;
